<template>
  <div>
    <div>
      <h1>Terms of Use</h1>
      <p>
        <strong>WEBSITE DISCLAIMER</strong><br />The information provided
        by&nbsp;Pamedia Services Ltd&nbsp;(“we,” “us” or “our”) on&nbsp;<a
          rel="noreferrer noopener"
          href="https://myforexglobal.com/"
          target="_blank"
          >https://myforexglobal.com/</a
        >&nbsp;(the “Site”)&nbsp;is for general informational purposes only. All
        information on the Site&nbsp;is provided in good faith, however we make
        no representation or warranty of any kind, express or implied, regarding
        the accuracy, adequacy, validity, reliability, availability or
        completeness of any information on the Site. UNDER NO CIRCUMSTANCE SHALL
        WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
        AS A RESULT OF THE USE OF THE SITE&nbsp;OR RELIANCE ON ANY INFORMATION
        PROVIDED ON THE SITE. YOUR USE OF THE SITE&nbsp;AND YOUR RELIANCE ON ANY
        INFORMATION ON THE SITE&nbsp;IS SOLELY AT YOUR OWN RISK.<br /><strong
          >EXTERNAL LINKS DISCLAIMER<br /></strong
        ><br />The&nbsp;Site&nbsp;may contain (or you may be sent through the
        Site) links&nbsp;to other websites or content belonging to or
        originating from third parties or links to websites and features in
        banners or other advertising. Such external links are not investigated,
        monitored, or checked for accuracy, adequacy, validity, reliability,
        availability or completeness by us. WE DO NOT WARRANT, ENDORSE,
        GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF
        ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE
        OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE
        WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
        TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR
        SERVICES.<br /><strong>PROFESSIONAL DISCLAIMER</strong><br />The Site
        cannot and does not contain&nbsp;forex trading&nbsp;advice.
        The&nbsp;forex trading&nbsp;information is provided for general
        informational and educational purposes only and is not a substitute for
        professional advice. Accordingly, before taking any actions based upon
        such information, we encourage you to consult with the appropriate
        professionals. We do not provide any kind of&nbsp;forex
        trading&nbsp;advice. THE USE OR RELIANCE OF ANY INFORMATION CONTAINED ON
        THIS SITE&nbsp;IS SOLELY AT YOUR OWN RISK.<br /><strong
          >AFFILIATES DISCLAIMER</strong
        ><br />The Site&nbsp;may contain links to affiliate websites, and we
        receive an affiliate commission for any purchases made by you on the
        affiliate website using such links.&nbsp;Our affiliates include the
        following:&nbsp;<br />● &nbsp;&nbsp;Clickbank● &nbsp;&nbsp;Hotforex●
        &nbsp;&nbsp;TradingView<br />We are a participant in the Amazon Services
        LLC Associates Program, an affiliate advertising program designed to
        provide a means for us to earn advertising fees by linking to Amazon.com
        and affiliated websites.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
